
 <div class="rts-banner-area-two eight main-banner">
    <swiper-container  navigation="true" slides-per-view="1"  loop="true"   speed="500"  space-between="10"> 
        <swiper-slide   *ngFor="let item of latestNewsObj;let i=index">
              <div class="bg_banner-three bg_image eight">
                <img src="{{item.NewsThumbnailUrl}}"  alt="{{item.ContentTitle}}" title="{{item.ContentTitle}}">
                
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="banner-three-inner">
                                <h1 class="title cd-headline clip is-full-width"><a  routerLink="/news-details/{{item.ContentID}}">{{item.ContentTitle}}</a></h1>
                                <p   [innerHtml]="item.ContentDiscription"></p> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </swiper-slide>
    </swiper-container>
</div> 

<!-- rts banner area end --> 
<section class="recent-news-section">
    <div class="container">
        <div class="row">
            <div class="col-md-9">
                <div class="inner-title">
                        <h2>Latest news</h2>
                    </div> 
                    <app-latestnews-section [latestNewsObj]="latestNewsObj"></app-latestnews-section>
            </div>
            <div class="col-md-3">
                <div class="category-right-main latest-news-right-main mb-0">
                    <div class="inner-title">
                        <h2>Latest news</h2>
                    </div>
                    <app-latestnewsside-section></app-latestnewsside-section>
              </div>
            </div>
        </div>
    </div>

</section>


<app-statenews-section [newslist]="newsListObj"></app-statenews-section>
 
     
             
<!-- start loader -->
<!-- <div class="loader-wrapper">
    <div class="loader">
    </div>
    <div class="loader-section section-left"></div>
    <div class="loader-section section-right"></div>
</div> -->
<!-- End loader -->

<!-- progress Back to top -->
<div class="progress-wrap">
    <svg class="progress-circle svg-content" width="100%" height="100%" viewBox="-1 -1 102 102">
        <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
    </svg>
</div>
<!-- progress Back to top End -->
<!-- <app-footer [NewsCategroyList]="newsCategoryObj"></app-footer> -->