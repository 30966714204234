import { Component, Input, OnInit } from '@angular/core';
import { ILatestnews, NEWSDETAIL_API_RESPONSE } from 'src/app/model/News';
import { WebserviceService } from 'src/app/services/webservice.service';

@Component({
  selector: 'app-latestnewsside-section',
  templateUrl: './latestnewssidelist.component.html',
  styleUrls: ['./latestnewssidelist.component.css']
})
export class LatestnewssidelistComponent implements OnInit {
  latestNewsList:Array<ILatestnews>=[];
  apiSubs:any;
  constructor(private webService:WebserviceService) { }

  ngOnInit(): void {
    this.newsList();
  }

  newsList(){
    this.apiSubs = this.webService.requestGet('menu').subscribe({
      next:(response:NEWSDETAIL_API_RESPONSE | any)=>{  
            this.latestNewsList = response.dataobject.latestnews;  
        },
      error:(e:any)=>{  
        this.latestNewsList = [];
      }
    });
  }
}
