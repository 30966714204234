import { Component, OnInit } from '@angular/core';
declare var $: any; 
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  ngOnInit(): void {
    //throw new Error('Method not implemented.');
    $(document).ready(() => {  
      console.log('Check:Jquery is working !!!');       
    });  
  }
  title = 'awaaznews';
}
