<nav class="nav-main mainmenu-nav d-none d-xl-block"> 
   <ul class="mainmenu">
            <li *ngFor="let item of menuListObj| slice:0:5;let i=index"   [ngClass]="item.Submenu.length == 0? 'home-class' : 'has-droupdown'" (click)="toggleNavbar(item.mCategoryID)">
                <a  class="menu-link"  id="MenuName{{item.mCategoryID}}">{{item.mCategoryName}}</a>
                <ul class="submenu"   id="Submenu{{item.mCategoryID}}" >
                     <li   *ngFor="let submenulist of item.Submenu" ><a href="/news-list/{{submenulist.CategoryID}}">{{submenulist.CategoryName}}</a></li> 
                 </ul>
            </li>
    </ul>
</nav>

 