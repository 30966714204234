 <!-- rts banner area start -->
 <section class="inner-banner-section">
    <img src="assets/images/news-list-banner.png" alt="News list" title="News list">
    <div class="inner-banner-main">
        <div class="container">
            <div class="col-md-12">
                <div class="banner-list-content">
                    <h1>News list</h1>
                    <ul>
                        <li><a href="#">Home</a></li>
                        <li>News list</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- rts banner area end -->
<section class="list-news-main">
    <div class="container">
        <div class="row">
            <div class="col-md-9">
                <div class="inner-title">
                    
                    <h2>{{newsCategoryObj[0].CategoryName}}</h2>
                </div>
                <div class="single-event-main align-items-center">
                    <div class="single-event-img">
                        <img src="{{newsCategoryObj[0].NewsThumbnailUrl}}" alt="" title="">
                    </div>
                    <div class="single-event">
                        <h3><a routerLink="/news-details/{{newsCategoryObj[0].ContentID}}">{{newsCategoryObj[0].ContentTitle}}</a></h3>
                        <!-- <p>{{newsCategoryObj[0].ContentDiscription|striphtml}}</p> -->
                        <p [innerHtml]="newsCategoryObj[0].ContentDiscription"></p>
                        <div class="calendar-icon">
                            <ul>
                                <li style="list-style:none; padding-right:10px;"><img src="https://aiservice.aisectinfotech.com/NewsImages/calendar-icon.svg" title="Calendar" alt="Calendar"> {{newsCategoryObj[0].CreatedDate | date: 'dd/MM/yyyy'}}</li>
                                <li style="list-style:none; padding-right:10px;"><img src="https://aiservice.aisectinfotech.com/NewsImages/clock-icon.svg" title="Clock" alt="Clock"> {{newsCategoryObj[0].CreatedDate | date: 'hh:mm a'}}</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="event-section">
                    <div class="row">
                        <div class="col-md-6" *ngFor="let categoryList of newsCategoryObj;">
                            <div class="single-event">
                                <div class="event-img">
                                    <img src="{{categoryList.NewsThumbnailUrl}}" alt="" title="">
                                </div>
                                <div class="event-contact">
                                    <h3><a routerLink="/news-details/{{categoryList.ContentID}}">{{categoryList.ContentTitle}}</a></h3>
                                    <!-- <p>{{categoryList.ContentDiscription|striphtml}}</p> -->
                                    <p [innerHtml]="categoryList.ContentDiscription"></p>
                                    <div class="calendar-icon">
                                        <ul style="display:flex;">
                                            <li style="list-style:none; padding-right:10px;"><img src="https://aiservice.aisectinfotech.com/NewsImages/calendar-icon.svg" title="Calendar" alt="Calendar"> {{categoryList.CreatedDate | date: 'dd/MM/yyyy'}}</li>
                                            <li style="list-style:none; padding-right:10px;"><img src="https://aiservice.aisectinfotech.com/NewsImages/clock-icon.svg" title="Clock" alt="Clock"> {{categoryList.CreatedDate | date: 'hh:mm a'}}</li>
                                        </ul>
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="category-right-main latest-news-right-main mb-0">
                    <div class="inner-title">
                        <h2>Latest news</h2>
                    </div>
                    <app-latestnewsside-section></app-latestnewsside-section>
                </div>
                <div class="category-right-main">
                    <div class="inner-title">
                        <h2>Category</h2>
                    </div>
                    <div class="category-right">
                        <ul>
                          <app-news-cateogory></app-news-cateogory>
                        </ul>
                    </div>
                </div>
            
            </div>
        </div>
    </div>
</section>



<!-- start loader -->
<div class="loader-wrapper">
    <div class="loader">
    </div>
    <div class="loader-section section-left"></div>
    <div class="loader-section section-right"></div>
</div>
<!-- End loader -->

<!-- progress Back to top -->
<div class="progress-wrap">
    <svg class="progress-circle svg-content" width="100%" height="100%" viewBox="-1 -1 102 102">
        <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
    </svg>
</div>

