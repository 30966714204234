<!-- <div class="header-main-two">
    <div class="container">
        <div class="category-main">
            <div class="header-category">
                <div class="vertical-menu">
                    <div class="category"><img src="assets/images/three-lines-icon.svg" alt="Category" title="Category"> Browse Category</div>
                    <ul class="vertical-menu-list" style="display: none;">
                        <li *ngFor="let item of newsCategorList ;let i=index" ><a  routerLink="news-list/{{item.CategoryID}}">{{item.CategoryName}}</a></li>
                    </ul>
                </div>
            </div>
            <app-notification [NewsCategroyList]="newsCategorList"></app-notification>
            <div class="button-area">
                <button id="search" class="rts-btn btn-primary-alta"><i class="far fa-search"></i></button>
            </div>
        </div>
    </div>
</div> -->
<li *ngFor="let item of newsCategorList ;let i=index" ><a  href="/news-list/{{item.CategoryID}}">{{item.CategoryName}}</a></li>
                    