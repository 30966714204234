import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder} from '@angular/forms';
import { WebserviceService } from 'src/app/services/webservice.service';
import { INewscategory, NEWSDETAIL_API_RESPONSE } from '../../model/News';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit {
  apiSubs:any;
  newsCategoryObj:Array<INewscategory>=[]; 
  SearchKey:any;
  step='step1';  
  @Input() className :any;
  constructor(private webService:WebserviceService,private formBuilder:FormBuilder,private activeroute:ActivatedRoute) { }
  checkoutForm = this.formBuilder.group({
    name: '',
    address: ''
  });

  ngOnInit(): void { 
 
 this.activeroute.params.subscribe(params => {
      console.log(params) //log the entire params object
      console.log(params['id'])
      
      this.SearchKey=params['id'];//log the value of id
    });
    if(this.SearchKey)this.searchDetail(this.SearchKey);
    localStorage.setItem('searchkey',this.SearchKey)
    if(localStorage.getItem('searchkey')){
      // alert('searchkey'+localStorage.getItem('searchkey'))
    }

  }

  searchDetail(SearchKey:any){ 
  const formData = new FormData();
  formData.append('start', '0');
  formData.append('length', '100');
  formData.append('orderByField', 'CategoryID');
  formData.append('orderType', 'ASC');
  formData.append('filterJSONData','{}');
  formData.append('search',SearchKey); 
   this.apiSubs = this.webService.requestPost('searchnewslist', formData,false).subscribe({
      next:(response:NEWSDETAIL_API_RESPONSE | any)=>{   
            this.newsCategoryObj=response.dataobject;  
            localStorage.removeItem('searchkey')
        },
      error:(e:any)=>{  
            this.newsCategoryObj = [];
      }
    });
  }
}

