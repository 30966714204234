import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { INewslist, NEWSDETAIL_API_RESPONSE } from 'src/app/model/News';
import { WebserviceService } from 'src/app/services/webservice.service';
 

// import jsPDF from 'jspdf';
// import html2canvas from 'html2canvas';import { DomSanitizer } from '@angular/platform-browser';
  ;
@Component({
  selector: 'app-news-emailler',
  templateUrl: './news-emailler.component.html',
  styleUrls: ['./news-emailler.component.css']
})
export class NewsEmaillerComponent implements OnInit { 
  apiSubs:any;
  data:any;
  currentDate:any;
  newsListObj:Array<INewslist>=[]; 
  awardNews:any=[];
  yearmonth: any;
  fileUrl:any; 
  buttonDownloadHtml=true;
  coppied_data:any;
  yearMonthByUrl:any;
  selectDate=false;
  @ViewChild('htmlData') htmlData!: ElementRef; 
  url:any;
  shareBtn=true;
  constructor(private webService:WebserviceService,private sanitizer: DomSanitizer,private activeRoute:ActivatedRoute) { }
  ngOnInit(): void {
    // this.getNews();
    this.currentDate = new Date(); 
    this.yearMonthByUrl = this.activeRoute.snapshot.params['YearMonth'];   
    if(this.yearMonthByUrl==undefined){ 
      this.selectDate=false;   
    }
    else{
      this.selectDate=true; 
      this.getNewsByUrl();
    }
   }
 
   getNews(){
    console.log(this.yearmonth);
    this.url='https://awaaz.aisect.org/news-emailer/'+this.yearmonth;
    console.log('url',this.url);
    this.apiSubs = this.webService.requestGet('newslistmonthandyearwise?MonthYear='+this.yearmonth).subscribe({
       next:(response:NEWSDETAIL_API_RESPONSE | any)=>{  
        console.log('test',response);
             this.newsListObj=response.dataobject; 
             this.awardNews=this.newsListObj[9];
             this.shareBtn=false;
         },
       error:(e:any)=>{  
         this.newsListObj = [];
       }
     });
   }

   getNewsByUrl(){
    console.log(this.yearMonthByUrl);
    this.url='https://awaaz.aisect.org/news-emailer/'+this.yearMonthByUrl;
    this.apiSubs = this.webService.requestGet('newslistmonthandyearwise?MonthYear='+this.yearMonthByUrl).subscribe({
       next:(response:NEWSDETAIL_API_RESPONSE | any)=>{  
        console.log('test',response);
             this.newsListObj=response.dataobject; 
             this.awardNews=this.newsListObj[9];
             this.shareBtn=false;
         },
       error:(e:any)=>{  
         this.newsListObj = [];
       }
     });
   }

   copyToClipboard() {
    /*const content = (document.getElementById('myDiv') as HTMLElement).innerHTML;
    navigator['clipboard'].writeText(content).then().catch(e => console.error(e));*/

    const content = (document.getElementById('myDiv') as HTMLElement).innerHTML;   

    let textArea =document.createElement("TEXTAREA");    
        textArea.innerHTML = content;
        document.body.append(textArea);
    
	if(typeof document.execCommand != 'undefined'){
	(textArea as any).select();
	document.execCommand('copy')
	}
  textArea.remove();
  }

 
   
// public openPDF(): void {
//     let DATA: any = document.getElementById('htmlData');
//     html2canvas(DATA).then((canvas) => {
//       let fileWidth = 208;
//       let fileHeight = (canvas.height * fileWidth) / canvas.width;
//       const FILEURI = canvas.toDataURL('image/png');
//       let PDF = new jsPDF('p', 'mm', 'a4');
//       let position = 0;
//       PDF.addImage(FILEURI, 'PNG', 0, position, fileWidth, fileHeight);
//       PDF.save('NewsDownload.pdf');
//     });
//   }

public generateHtml(){
  const content = (document.getElementById('htmlData') as HTMLElement).innerHTML;   
  let textArea =document.createElement("TEXTAREA");    
        textArea.innerHTML = content;
        document.body.append(textArea); 
	if(typeof document.execCommand != 'undefined'){
	(textArea as any).select();
  document.execCommand('copy')
	}      
    textArea.remove();
    this.paste();
}

async paste() {
  this.coppied_data = await navigator.clipboard.readText();  
  const blob = new Blob([this.coppied_data], { type: 'application/octet-stream' });
   this.fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(blob));
   if(this.fileUrl) this.buttonDownloadHtml=false;
   }
 
 

}
 

