import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { LayoutComponent } from './layouts/base-layout/layout.component';
import { CategoryWiseNewsListComponent } from './pages/category-wise-news-list/category-wise-news-list.component';
import { NewsEmaillerComponent } from './pages/news-emailler/news-emailler.component';
import { NewsdetailComponent } from './pages/newsdetail/newsdetail.component';
import { SearchComponent } from './component/search/search.component';

//const routes: Routes = [];

const routes: Routes = [
  { 
    path: '', 
    component: LayoutComponent,  
    children: [
      { path: '', component: HomeComponent, pathMatch: 'full'},
      { path: 'news-list/:id', component: CategoryWiseNewsListComponent},
      { path: 'news-emailer', component: NewsEmaillerComponent},
      { path: 'news-emailer/:YearMonth', component: NewsEmaillerComponent},
      { path: 'news-details/:id', component: NewsdetailComponent},   
      { path: 'Search/:id', component: SearchComponent},   
    ]
  },
  { path: '**', redirectTo: '' , pathMatch: 'full'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
