     <!-- start header area -->
<header class="header--sticky header-one ">
<div class="header-top header-top-one bg-1">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 d-xl-block d-none">
                    <div class="left">
                        <div class="mail">
                            <a href="mailto:awaaz@aisect.org"><i class="fal fa-envelope"></i> awaaz@aisect.org</a>
                         
                        </div>
                        <!-- <div class="mail"> -->
                            <!-- <a href="tel:"><i class="fal fa-phone-alt"></i></a> -->
                        <!-- </div> -->
                    </div>
                </div>
                <div class="col-lg-6 d-xl-block d-none">
                    <div class="right">
                        <ul class="social-wrapper-one">
                            <li>Follow Us:</li>
                            <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                            <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                            <li><a href="#"><i class="fab fa-instagram"></i></a></li>
                            <li><a class="mr--0" href="#"><i class="fab fa-linkedin-in"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="header-main-one bg-white">
        <div class="container">
            <div class="row">
                <div class="col-xl-3 col-lg-4 col-md-4 col-sm-4 col-4">
                    <div style="margin-top:10px;">
                        <a [routerLink]="['/']">
                            <img src="assets/images/Logo.png"   alt="finbiz-logo"> 
                        </a>
                    </div>
                </div>

                <div class=" col-xl-9 col-lg-8 col-md-8 col-sm-8 col-8">
                    <div class="main-header">
                        <!--Start  desktopmenulist  -->
                        <app-menulist  [menuListObj]="menuListObj" ></app-menulist>
                        <!-- End of desktopmenulist -->
                        <div class="button-area heaer-nav">
                            <!-- <button id="search" class="rts-btn btn-primary-alta"><i class="far fa-search"></i></button> -->
                            <button id="menu-btn" class="menu rts-btn btn-primary-alta ml--20 ml_sm--5">
                                <img class="menu-dark" src="assets/images/menu.png" alt="Menu-icon">
                                <img class="menu-light" src="assets/images/menu-light.png" alt="Menu-icon">
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="header-main-two">
        <div class="container">
            <div class="category-main">
                <div class="header-category">
                    <div class="vertical-menu">
                        <div class="category"><img src="assets/images/three-lines-icon.svg" alt="Category" title="Category"> Browse Category</div>
                        <ul class="vertical-menu-list" style="display: none;">
                            <!-- <li *ngFor="let item of newsCategoryObj ;let i=index" ><a  routerLink="news-list/{{item.CategoryID}}">{{item.CategoryName}}</a></li> -->
                             <app-news-cateogory></app-news-cateogory>
                        </ul>
                    </div>
                </div>
                <app-notification [NewsCategroyList]="newsCategoryObj"></app-notification>
                <div class="button-area">
                    <button id="search" class="rts-btn btn-primary-alta"><i class="far fa-search"></i></button>
                </div>
            </div>
        </div>
    </div>
</header>
     <!-- End header area -->
     <!-- End header area -->
<div id="side-bar" class="side-bar">
<button class="close-icon-menu"><i class="far fa-times"></i></button>
<!-- inner menu area desktop start -->
<div class="rts-sidebar-menu-desktop">
    <a class="logo-1" href="#"><img class="logo" src="assets/images/Logo.png" alt="finbiz_logo"></a>
    <div class="body-mobile d-block d-xl-none">
        <!-- start mobile menu  -->
        <app-menulist [menuListObj]="menuListObj" ></app-menulist>
        <!-- End mobile menu  -->
        <div class="social-wrapper-two menu mobile-menu">
            <a href="#"><i class="fab fa-facebook-f"></i></a>
            <a href="#"><i class="fab fa-twitter"></i></a>
            <a href="#"><i class="fab fa-instagram"></i></a>
            <a href="#"><i class="fab fa-whatsapp"></i></a>
            <!-- <a href="#"><i class="fab fa-linkedin"></i></a> -->
        </div>
    </div>
</div>
<!-- inner menu area desktop End -->
</div>

<div class="search-input-area">
    <div class="container">
        <form  #f="ngForm" (submit)="Search(f.value)">
        <div class="search-input-inner">
            <div class="input-div">
                <input id="searchInput1"   #uname="ngModel" class="search-input" name="search_keyward" [(ngModel)]="search_keyward"  type="text" placeholder="Search News ">
                <button type="submit"><i class="far fa-search"></i></button>
            </div>
        </div>
        </form>
        </div>
        <div id="close" class="search-close-icon"><i class="far fa-times"></i></div>
</div>


<div id="anywhere-home">
</div><!-- ENd Header Area -->