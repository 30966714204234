import { Component, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { IMenu } from 'src/app/model/News';

@Component({
  selector: 'app-menulist',
  templateUrl: './menulist.component.html',
  styleUrls: ['./menulist.component.css']
})
export class MenulistComponent implements OnInit {
  isNavbarOpen: boolean = false;
  item:any;
  redirecturl='news-list';
  @Input() menuListObj: Array<IMenu> = [];  
  constructor(private elementRef: ElementRef,private renderer: Renderer2){


  }
  ngOnInit(){


  }
  toggleNavbar(categoryId:any) {
    console.log('mcateid',categoryId)
// let element = event.target || event.srcElement || event.currentTarget;
// // Get the id of the source element
// let elementId = element.id;
// console.log('Test Juneer',elementId)

const div = this.elementRef.nativeElement.querySelector('#Submenu'+categoryId);
console.log('div',div)
 
//     console.log('click')
    this.isNavbarOpen = !this.isNavbarOpen;
    if (this.isNavbarOpen == true ) {
      console.log('class added')
      // document.body.classList.add('open');
      this.renderer.addClass(div, 'active');
    } else {
      console.log('class remove')
      // document.body.classList.remove('open');
      this.renderer.removeClass(div, 'active');
    }
    // .main-content
  }
}


