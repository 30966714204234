import { Component, Input, OnInit } from '@angular/core';
import { ILatestnews, IMenu, INewscategory, INewslist, NEWSDETAIL_API_RESPONSE } from 'src/app/model/News';
import { WebserviceService } from 'src/app/services/webservice.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent  implements OnInit {
  
  mobileClass='nav-main mainmenu-nav';
  desktopClass='nav-main mainmenu-nav d-none d-xl-block';  
  apiSubs:any;
  menuListObj : Array<IMenu> = [];
  latestNewsObj:Array<ILatestnews>=[];
  newsCategoryObj:Array<INewscategory>=[];
  newsListObj:Array<INewslist>=[];
  images:any; 
  responsiveOptions:any;
  step='step1';  
  @Input() className :any;

  constructor(private webService:WebserviceService) {
    
   }

  ngOnInit(): void {
   this.menuList();
  }

  menuList(){
    this.apiSubs = this.webService.requestGet('menu').subscribe({
      next:(response:NEWSDETAIL_API_RESPONSE | any)=>{ 
            this.menuListObj = response.dataobject.menu; 
            this.latestNewsObj = response.dataobject.latestnews; 
            this.newsCategoryObj=response.dataobject.newscategory;
            this.newsListObj=response.dataobject.newslist; 
        },
      error:(e:any)=>{  
        this.menuListObj = [];
      }
    });
  }


  copyToClipboard() {
    /*const content = (document.getElementById('myDiv') as HTMLElement).innerHTML;
    navigator['clipboard'].writeText(content).then().catch(e => console.error(e));*/

    const content = (document.getElementById('myDiv') as HTMLElement).innerHTML;   

    let textArea =document.createElement("TEXTAREA");    
        textArea.innerHTML = content;
        document.body.append(textArea);
    
	if(typeof document.execCommand != 'undefined'){
	(textArea as any).select();
	document.execCommand('copy')
	}      
    textArea.remove();

  }
}

