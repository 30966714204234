import { Component, Input, OnInit } from '@angular/core';
import { ILatestnews, INewscategory, NEWSDETAIL_API_RESPONSE } from 'src/app/model/News';
import { WebserviceService } from 'src/app/services/webservice.service';

@Component({
  selector: 'app-news-cateogory',
  templateUrl: './menu-cateogory.component.html',
  styleUrls: ['./menu-cateogory.component.css']
})
export class MenuCateogoryComponent implements OnInit {
  newsCategorList:Array<INewscategory>=[];
  apiSubs:any;
  constructor(private webService:WebserviceService) { }

  ngOnInit(): void {
    this.newsList();
  }

  newsList(){
    this.apiSubs = this.webService.requestGet('menu').subscribe({
      next:(response:NEWSDETAIL_API_RESPONSE | any)=>{  
            this.newsCategorList = response.dataobject.newscategory;  
        },
      error:(e:any)=>{  
        this.newsCategorList = [];
      }
    });
  }
}
