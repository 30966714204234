
<div class="header-recent-posts-wrap recent-posts-silder" style="color:#ffffff">
    <swiper-container   slides-per-view="5"  loop="true"   speed="500"  space-between="3">
                    <swiper-slide     *ngFor="let item of NewsCategroyList ;let i=index" >
                        <div class="team-single-one-start">
                            <div class="team-image-area">
                                <a href="news-list/{{item.CategoryID}}">
                                    {{item.CategoryName|slice:0:25}}
                                </a>
                            </div>
                        </div>
                    </swiper-slide>
    </swiper-container>
</div>