import { Component, Input, OnInit } from '@angular/core';
import { ILatestnews, IMenu, INewscategory, INewslist, NEWSDETAIL_API_RESPONSE } from 'src/app/model/News';
import { WebserviceService } from 'src/app/services/webservice.service';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
 
  apiSubs:any;
  menuListObj : Array<IMenu> = [];
  latestNewsObj:Array<ILatestnews>=[];
  newsCategoryObj:Array<INewscategory>=[];
  newsListObj:Array<INewslist>=[];
    
  step='step1';  
  @Input() className :any;
  constructor(private webService:WebserviceService) { }


  ngOnInit(): void {
    this.menuList();
   }
 

  
  menuList(){
    this.apiSubs = this.webService.requestGet('menu').subscribe({
      next:(response:NEWSDETAIL_API_RESPONSE | any)=>{ 
            this.menuListObj = response.dataobject.menu; 
            this.latestNewsObj = response.dataobject.latestnews; 
            this.newsCategoryObj=response.dataobject.newscategory;
            this.newsListObj=response.dataobject.newslist; 
        },
      error:(e:any)=>{  
        this.menuListObj = [];
      }
    });
  }
}
