
import { Component, HostListener, Input, OnInit,} from '@angular/core';
import { INewslist } from 'src/app/model/News';
import Swiper from 'swiper';

@Component({
  selector: 'app-statenews-section',
  templateUrl: './statenewslist.component.html',
  styleUrls: ['./statenewslist.component.css']
})
export class StatenewslistComponent implements OnInit{
  @Input() newslist:Array<INewslist>=[];
  slidesPerView:number = 3;
  screenWidth!: number;
  constructor() {
   }

   
  ngOnInit() {
   
    var swiper = new Swiper('.swiper-container', {
      slidesPerView: 1,
      spaceBetween: 10,
      // init: false,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      breakpoints: {
        640: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
        768: {
          slidesPerView: 3,
          spaceBetween: 40,
        },
        1024: {
          slidesPerView: 4,
          spaceBetween: 50,
        },
      }
    });
  }
 
  @HostListener('window:resize')
  getScreenWidth(){
    this.screenWidth = window.innerWidth;
    if(this.screenWidth >= 320 && this.screenWidth <= 480){
      this.slidesPerView = 1;
    }
   else if(this.screenWidth >= 480 && this.screenWidth <= 992){
      this.slidesPerView = 2;
    }
    else if(this.screenWidth >= 992 && this.screenWidth <= 1200){
      this.slidesPerView = 3;
    }
  }
 
}
