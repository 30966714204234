    <div class="container" style="padding-top: 250px;">
        <div class="col-lg-12" [hidden]="selectDate">
            <form   (ngSubmit)="getNews()">
                <div class="col-lg-3"> 
                    <input type="month" id="yearmonth"  name="yearmonth"  [(ngModel)]="yearmonth"> 
                </div>
                <div class="col-lg-3">
                    <button class="btn btn-info" >Submit</button>
                </div>
            </form>
        </div>
        <div  id="htmlData">

 
        <div class="col-lg-12" style="padding: 60px;" id="myDiv"> 
            <div class="gmail_quote"><div dir="ltr">
                <div class="gmail_quote">
                <div class="adM"></div>
                <div style="font-family:roboto!important;line-height:18px;text-align:justify;font-size:13px">
                <div class="adM"></div>
                <div style="width:810px;margin:0 auto;text-align:justify" class="full-with">
                <div class="adM"></div> 
                <div _ngcontent-mvh-c30="" style="width: 810px; float: left;" class="full-with"><img _ngcontent-mvh-c30="" alt="" src="http://awaaz.aisect.org/assets/images/aisect_awaaz.jpg" data-bit="iit" tabindex="0" width="810" class="CToWUd a6T full-with"><div _ngcontent-mvh-c30="" dir="ltr" class="a6S" style="opacity: 0.01; left: 911px; top: 379px;"><div _ngcontent-mvh-c30="" id=":1el" role="button" tabindex="0" aria-label="Download attachment " jslog="91252; u014N:cOuCgd,Kr2w4b,xr6bB" data-tooltip-class="a1V" data-tooltip="Download" class="T-I J-J5-Ji aQv T-I-ax7 L3 a5q"><div _ngcontent-mvh-c30="" class="akn"><div _ngcontent-mvh-c30="" class="aSK J-J5-Ji aYr"></div></div></div></div><img _ngcontent-mvh-c30="" alt="" src="http://awaaz.aisect.org/assets/images/newscon.png" data-bit="iit" tabindex="0" width="775" class="CToWUd a6T" style="margin-left: 21px;"><hr _ngcontent-mvh-c30=""><h2 _ngcontent-mvh-c30="" style="text-align: center;"> Release - <span _ngcontent-mvh-c30="">{{ yearmonth | date: 'MMM yyyy'}}</span></h2><hr _ngcontent-mvh-c30=""></div>
                <div style="width:740px;margin:0 auto" class="full-with">
                <div style="clear:both;height:10px;margin-bottom:35px">&nbsp;</div>

                <div style="width:730px;float:left" class="full-with">
                    <img alt="" src="http://awaaz.aisect.org/assets/images/cover.jpg" class="CToWUd" data-bit="iit" width="730px" class="full-with"> 
                    
                </div>

                <div style="clear:both;height:13px">&nbsp;</div> 

                <!-- <div class="card"> -->
                <div class="card" *ngFor="let item of newsListObj|slice:0:1">
                    <div class="card-body"   style="display: inline-block; border-bottom: 2px solid #9e9e9e61; margin-bottom: 20px; padding-bottom: 10px;">
                        <div style="clear:both;height:15px">&nbsp;</div>
                        <div style="width:100%">
                            <div>
                                <a style="color:#0053e2;font-size:20px"  >{{newsListObj[9].CategoryName}} </a>  
                            </div>
                            <div *ngFor="let subitem of newsListObj[9].Content|slice:0:1">
                                <div style="clear:both;height:15px">&nbsp;</div>
                                <div style="width:100%">
                                    <div style="float:left;width:30%"> 
                                        <a href="https://awaaz.aisect.org/news-details/{{subitem.ContentID}}" > 
                                            <img alt="" src="{{subitem.NewsThumbnailUrl}}" class="CToWUd" data-bit="iit" width="230" height="160">
                                        </a>
                                    </div>
                                    <div style="float:left;width:65%;font-size:13px;margin-left:3%">
                                        <a href="https://awaaz.aisect.org/news-details/{{subitem.ContentID}}"  style="color:#ff8212; padding-bottom: 8px; display: inline-block;" target="_blank"><span style="font-size:16px"> <strong style="font-weight: normal!important;">{{subitem.ContentTitle}}</strong></span></a>
                                        <br>
                                        <span style="font-size:15px;" innerHTML="{{subitem.ContentDiscription}}"></span> 
                                        <div class="calendar-icon">
                                            <ul style="display:flex;">
                                                <li style="list-style:none; padding-right:10px;"><img src="https://aiservice.aisectinfotech.com/NewsImages/calendar-icon.svg" title="Calendar" alt="Calendar"> {{subitem.CreatedDate | date: 'dd/MM/yyyy'}}</li>
                                                <li style="list-style:none; padding-right:10px;"><img src="https://aiservice.aisectinfotech.com/NewsImages/clock-icon.svg" title="Clock" alt="Clock"> {{subitem.CreatedDate | date: 'hh:mm a'}}</li>
                                            </ul>
                                        </div> 
                                        <a href="https://awaaz.aisect.org/news-details/{{subitem.ContentID}}" style="text-decoration:none;color:#0053e2" target="_blank">Read More...</a> <!--/{{item.itemTitle.split(' ').join('-')}}-->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style="clear:both;height:5px">&nbsp;</div>

                <div style="width:730px;float:left" class="full-with">
                    <img alt="" src="http://awaaz.aisect.org/assets/images/cover-border.jpg" class="CToWUd" data-bit="iit" width="730px" class="full-with">
                </div>

                <div style="clear:both;height:10px;margin-bottom:35px">&nbsp;</div>

                <div style="width:740px;margin:0 auto" class="full-with">

                <div style="width:740px;margin:0 auto" class="full-with">
                <div>
                <p>
                    <img alt="" src="http://awaaz.aisect.org/assets/images/stories.png" class="CToWUd" data-bit="iit" width="750px"> 
                    <!-- <img alt="" src="https://ci6.googleusercontent.com/proxy/06iR2f2v6aSc9rOnZfOkYhDuXbMs2BFQuts7gbIziW2_IH1ttXmwldu175Ch9LNin8RDg4sxP0vQbYFN_j1Yees0FU3wzKqfDQ=s0-d-e1-ft#https://defindia.org/wp-content/uploads/2017/02/04.png" class="CToWUd" data-bit="iit"> -->
                </p>
                </div>

                <div style="clear:both;height:8px;margin-bottom:40px">&nbsp;</div>
 
                            <div class="card" *ngFor="let item of newsListObj | slice:1; ">
                                <div class="card-body"   style="display: inline-block; border-bottom: 2px solid #9e9e9e61; margin-bottom: 20px; padding-bottom: 10px;">
                                    <div style="clear:both;height:15px">&nbsp;</div>
                                    <div style="width:100%">
                                        <div>
                                            <a style="color:#0053e2;font-size:20px"  >{{item.CategoryName}} </a> <!--/{{submenulist?.categoryName.split(' ').join('-')}}-->
                                        </div>
                                        <div *ngFor="let subitem of item.Content">
                                            <div style="clear:both;height:15px">&nbsp;</div>
                                            <div style="width:100%">
                                                <div style="float:left;width:30%">
                                                    
                                                    <a href="https://awaaz.aisect.org/news-details/{{subitem.ContentID}}"> <!--/{{item.itemTitle.split(' ').join('-')}}-->
                                                        <img alt="" src="{{subitem.NewsThumbnailUrl}}" class="CToWUd" data-bit="iit" width="230" height="160">
                                                    </a>
                                                </div>
                                                <div style="float:left;width:65%;font-size:13px;margin-left:3%">
                                                    <a href="https://awaaz.aisect.org/news-details/{{subitem.ContentID}}" style="color:#ff8212; padding-bottom: 8px; display: inline-block;" target="_blank"><span style="font-size:16px"> <strong style="font-weight: normal!important;">{{subitem.ContentTitle}}</strong></span></a>
                                                    <br>
                                                    <span style="font-size: 15px;"  innerHTML="{{subitem.ContentDiscription}}... "></span> 
                                                    <div class="calendar-icon">
                                                        <ul style="display:flex;">
                                                            <li style="list-style:none; padding-right:10px;"><img src="https://aiservice.aisectinfotech.com/NewsImages/calendar-icon.svg" title="Calendar" alt="Calendar"> {{subitem.CreatedDate | date: 'dd/MM/yyyy'}}</li>
                                                            <li style="list-style:none; padding-right:10px;"><img src="https://aiservice.aisectinfotech.com/NewsImages/clock-icon.svg" title="Clock" alt="Clock"> {{subitem.CreatedDate | date: 'hh:mm a'}}</li>
                                                        </ul>
                                                    </div> 
                                                    <a href="https://awaaz.aisect.org/news-details/{{subitem.ContentID}}" style="text-decoration:none;color:#0053e2" target="_blank">Read More...</a> <!--/{{item.itemTitle.split(' ').join('-')}}-->

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                              
                            </div>
                            <!--  share on whatsapp-->
                                <div style="clear:both;height:10px; margin-bottom:35px" [hidden]="shareBtn">&nbsp;   
                                    <div class="whatsapp-main">
                                        <a class="whatsapp" href="https://api.whatsapp.com/send?text={{url}}" data-action="share/whatsapp/share"target="_blank" title="Share on Whatsapp"><i class="fab fa-whatsapp"></i> <span>Share on Whatsapp</span></a></div>
                                    </div>
                                </div>
                            <!--  share on whatsapp-->
               
                <div style="clear:both;height:8px;margin-bottom:35px">&nbsp;</div>

                <div style="width:730px;float:left" class="full-with">
                    <img alt="" src="http://awaaz.aisect.org/assets/images/cover-border.jpg" class="CToWUd" data-bit="iit" width="750px" class="full-with">
                </div>
            </div>

               

                </div>

                <div style="clear:both;height:10px;margin-bottom:35px">&nbsp;</div>

                <div style="clear:both;height:10px;margin-bottom:35px">&nbsp;</div>

                <div style="width:810px;margin:0 auto;background:#58595b;float:left;margin:0 auto" class="full-with">
                
                <div style="text-align:center;color:#f1f1f2;padding:10px;font-size:12px">AISECT LTD.</div>
 
                <div style="text-align:center" class="iconfab" >
                        <a href="https://www.facebook.com/AISECTNetwork/" rel="external" style="color:#f1f1f2; margin-right: 8px;" target="_blank" class="facebook-f-share-button large-share-button"><span class="fab fa-facebook-f fa-2x"></span></a>
                        <a href="https://twitter.com/aisectindia?lang=en" rel="external" style="color:#f1f1f2;" style="color:#f1f1f2; margin-right: 8px;" target="_blank" class="twitter-share-button large-share-button"><span class="fab fa-twitter fa-2x"></span> </a>
                        <a href="https://www.instagram.com/yuvaazyouthkiaawaj/" rel="external" style="color:#f1f1f2;" style="color:#f1f1f2; margin-right: 8px;" target="_blank" class="instagram-share-button"><span class="fab fa-instagram fa-2x"></span> </a>
                        <a href="https://in.linkedin.com/company/aisect-ltd-" rel="external" style="color:#f1f1f2;" style="color:#f1f1f2; margin-right: 8px;" target="_blank" class="linkedin-share-button"><span class="fab fa-linkedin fa-2x"></span> </a>
                        <a href="https://www.youtube.com/channel/UCOQfuenQuteV2FVlwAYO2sg" style="color:#f1f1f2;" style="color:#f1f1f2; margin-right: 8px;" rel="external" target="_blank" class="youtube-share-button"><span class="fab fa-youtube fa-2x"></span> </a>
                        <!-- <a href="#" rel="external" target="_blank" class="whatsapp-share-button"><span class="fa fa-whatsapp fa-2x"></span> </a> -->
                </div>
                    <p style="text-align:center;font-size:14px;color:#f1f1f2">
                        <a href="mailto:awaaz@aisect.org" target="_blank"><i class="icon-item far fa-envelope" aria-hidden="true"></i> awaaz@aisect.org</a>
                        <!-- <a href="mailto:" target="_blank">awaaz.aisect.org</a> -->
                    </p>
                </div>
                </div>
                </div>

                </div>
                </div>
            </div>
        </div>
         </div>

      

    
        <div class="col-lg-12" style="padding: 10px; text-align: center;margin-bottom: 10%;;">
            <div class="col-lg-2"></div>
            <div class="col-lg-2"> 
                <!-- <button class="btn btn-info copy-btn" (click)="openPDF()"><i class="fa fa-file-pdf" aria-hidden="true"></i>&nbsp; Download Pdf</button> -->
            </div>
            <div class="col-lg-2" *ngIf="newsListObj[0]?.CategoryID" [hidden]="selectDate">
                <button class="btn btn-warning copy-btn" (click)="generateHtml()"><i class="fa fa-copy"></i>&nbsp; Copy Html</button>
            </div>
            <div class="col-lg-2" *ngIf="newsListObj[0]?.CategoryID" [hidden]="selectDate">  
                <a [href]="fileUrl"  download="{{yearmonth}}.html"><button  class="btn btn-danger copy-btn" [disabled]="buttonDownloadHtml" ><i class="fa fa-download" aria-hidden="true" ></i>&nbsp; Download Html File</button></a>
                <!-- <a [href]="fileUrl"  download="{{yearmonth}}.html"  class="btn btn-success copy-btn"><i class="fa fa-download" aria-hidden="true" ></i>&nbsp; Download Html File</a> -->
            </div>
             <div class="col-lg-2"></div>
        </div>
    </div> 

