import {CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BaseComponent } from './base/base.component';
import { HeaderComponent } from './layouts/header/header.component';
import { FooterComponent } from './layouts/footer/footer.component';
import { HomeComponent } from './pages/home/home.component';
import { LayoutComponent } from './layouts/base-layout/layout.component';
import { StripHtmlPipe } from './validators/htmltagremoval';
import { NewsdetailComponent } from './pages/newsdetail/newsdetail.component';
import { NewsEmaillerComponent } from './pages/news-emailler/news-emailler.component';
import { CategoryWiseNewsListComponent } from './pages/category-wise-news-list/category-wise-news-list.component';
import { NotificationComponent } from './component/notification/notification.component';
import { StatenewslistComponent } from './component/statenewslist/statenewslist.component';
import { LatestnewssidelistComponent } from './component/latestnewssidelist/latestnewssidelist.component';
import { LatestnewslistComponent } from './component/latestnewslist/latestnewslist.component';
import { MenulistComponent } from './pages/menulist/menulist.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http'; 
import { register } from 'swiper/element/bundle';
import { SearchComponent } from './component/search/search.component';
import { MenuCateogoryComponent } from './component/news-cateogory/menu-cateogory.component';
// register Swiper custom elements
register();



//add page/component here
@NgModule({
  declarations: [
    AppComponent,
    BaseComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    MenulistComponent,
    LatestnewslistComponent,
    LatestnewssidelistComponent,
    StatenewslistComponent,
    NotificationComponent,
    MenuCateogoryComponent,
    CategoryWiseNewsListComponent,
    NewsEmaillerComponent,
    NewsdetailComponent, 
    LayoutComponent ,
    StripHtmlPipe,
    SearchComponent
  ],
  imports: [
    //add module/library/package here
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule ,
  
   
  ],
  providers: [ ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
