import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WebserviceService { 
  private APIURL = environment.baseUrl;
  constructor(public http:HttpClient) { }
 
 
// getArticleListbyMenuID(CategoryID:any,PageNo:any){  
//   return this.http.get<any>('http://awaaz.aisect.org/api/wsarticleapi/?CategoryID='+this.CategoryID+'&PageNo='+PageNo)
//   .toPromise()
//   .then(res => <INewsdetail[]>res.DepartmentListResult.datalist)
//   .then(res => { return res; });

// }
 
requestGet(endUrl: string) {
  return this.http.get(this.APIURL + endUrl);
}//EOF re1uestGet

requestPost(endUrl: string, datarequest?: any,isJson ?: boolean) {
  return this.http.post(this.APIURL + endUrl, datarequest);
}//EOF requestPost




}
