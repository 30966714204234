 <!-- rts banner area start --> 
<section class="inner-banner-section">
    <img src="assets/images/news-list-banner.png" alt="News list" title="News list">
    <div class="inner-banner-main">
        <div class="container">
            <div class="col-md-12">
                <div class="banner-list-content news-details-banner">
                    <h1>{{newsDetailObj.ContentTitle}}</h1>
                    <ul>
                        <li><a href="#">Home</a></li>
                        <li>News Details</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- rts banner area end -->
<section class="details-section">
    <div class="container">
        <div class="row">
            <div class="col-md-10 offset-md-1">
               <div class="details-banner">
                <h1 class="fw-bold">{{newsDetailObj.ContentTitle}}</h1> 
                <div class="calendar-icon">
                    <ul>
                        <li style="list-style:none; padding-right:10px;"><img src="https://aiservice.aisectinfotech.com/NewsImages/calendar-icon.svg" title="Calendar" alt="Calendar"> {{newsDetailObj.CreatedDate | date: 'dd/MM/yyyy'}}</li>
                        <li style="list-style:none; padding-right:10px;"><img src="https://aiservice.aisectinfotech.com/NewsImages/clock-icon.svg" title="Clock" alt="Clock"> {{newsDetailObj.CreatedDate | date: 'hh:mm a'}}</li>
                    </ul>
                </div> 
                   <div class="row">

                        <div class="col-12">
                            <swiper-container  navigation="true"   loop="true"   speed="500"   >
                                <swiper-slide>
                                       <img src="{{newsDetailObj.NewsThumbnailUrl}}" style="width:100%;" alt="{{newsDetailObj.ContentTitle}}" title="">
                                </swiper-slide>
                                <swiper-slide>
                                    <img src="{{newsDetailObj.NewsThumbnailUrl}}" style="width:100%;" alt="{{newsDetailObj.ContentTitle}}" title="">
                             </swiper-slide>
                            </swiper-container>
                        </div>
                    </div>
               </div>
               <div class="details-content">
                    <div class="by-admin-box"></div>
                    <div [innerHtml]="newsDetailObj.ContentDiscription"></div>
                </div>
           </div>
       </div>
    </div>
</section> 
 