import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { INewscategory, INewslist, NEWSDETAIL_API_RESPONSE } from 'src/app/model/News';
import { WebserviceService } from 'src/app/services/webservice.service';

@Component({
  selector: 'app-category-wise-news-list',
  templateUrl: './category-wise-news-list.component.html',
  styleUrls: ['./category-wise-news-list.component.css']
})
export class CategoryWiseNewsListComponent implements OnInit {
 
  apiSubs:any;

  newsCategoryObj:Array<INewscategory>=[];
  newsListObj:Array<INewslist>=[];
  categoryId:any;
  step='step1';  
  @Input() className :any;
  constructor(private webService:WebserviceService,private formBuilder:FormBuilder,private router:ActivatedRoute) { }
  checkoutForm = this.formBuilder.group({
    name: '',
    address: ''
  });

  ngOnInit(): void {
    this.categoryId = this.router.snapshot.params['id'];
    if(this.categoryId)this.newsList(this.categoryId);
  }

  newsList(categoryID:any){ 
  const formData = new FormData();
  formData.append('start', '0');
  formData.append('length', '100');
  formData.append('orderByField', 'CategoryID');
  formData.append('orderType', 'ASC');
  formData.append('filterJSONData','{}'); 
 
    this.apiSubs = this.webService.requestPost('newslistcategorywise?CategoryID='+categoryID, formData,false).subscribe({
      next:(response:NEWSDETAIL_API_RESPONSE | any)=>{   
            this.newsCategoryObj=response.dataobject; 
            console.log('tehee',this.newsCategoryObj)
        },
      error:(e:any)=>{  
            this.newsCategoryObj = [];
      }
    });
  }
}

