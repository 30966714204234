
<div class="latest-post-main"  *ngFor="let item of latestNewsList| slice:0:4;let i=index">
    <div class="latest-post">
        
        <img src="{{item.NewsThumbnailUrl}}" alt="" title="">
        <div class="post-content">
           <a routerLink="/news-details/{{item.ContentID}}"><span >{{item.ContentTitle|slice:0:20}}</span></a> 
       
                <div class="calendar-icon">
                    <ul>
                        <li><img src="https://aiservice.aisectinfotech.com/NewsImages/calendar-icon.svg" width="15px" title="Calendar" alt="Calendar"> {{item.CreatedDate | date: 'dd/MM/yyyy'}}</li>
                        <li><img src="https://aiservice.aisectinfotech.com/NewsImages/clock-icon.svg"   width="15px" title="Clock" alt="Clock"> {{item.CreatedDate | date: 'hh:mm a'}}</li>
                    </ul>
                </div>  
        </div>
    </div> 
</div>