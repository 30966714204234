<!-- SOF:: Header should be define here-->
<app-header></app-header>
<!-- EOF:: Header should be define here-->

<!-- SOF:: Main Container start from here-->
<router-outlet></router-outlet>
  <!-- Other / Inner component will render here-->

<!-- EOF:: Main Container start from here-->

<!-- SOF:: Footer should be define here-->
<app-footer></app-footer>
<!-- EOF:: Header should be define here-->

