import { Component, inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-base',
  template: `
    <p>
      base works!
    </p>
  `,
  styles: [
  ]
})
/*
* this is base component,you will have to extend other component from this base component,
* here you can define methods, property and other features that you want to use in other component
* no need to define same functions which is used in other, you can define here and access form other
* methods, property should be public / protected please do not use private
*/
export class BaseComponent implements OnInit {
  protected router = inject(Router);
  constructor() {     
  }

  /* 
  * init function 
  */
  ngOnInit(): void {
    console.log('BaseComponent ngOnInit',this.router);
  }//EOF ngOnInit

  /*
  * page redirect method , you can call this method from other component
  * @params routename : this page name
  */
  openPage(routename: string) {
    this.router.navigateByUrl(`/${routename}`);
  }//EOF openPage

}
