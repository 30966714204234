
<div *ngFor="let mainnewslist of newslist">
<div class="university-section" *ngIf="mainnewslist.Content.length!=0">
    <div class="container">
        <div class="university-main" >
            <div class="row">
                <div class="col-md-12">
                    <div class="main-title">
                        <h2>{{mainnewslist.CategoryName}}</h2> 
                        <a href="news-list/{{mainnewslist.CategoryID}}" class="read-btn">Read More<i class="fad fa-chevron-double-right"></i></a>
                    </div>
                </div>
            </div>
            <div class="swiper university-silder">
                <swiper-container navigation="true"  [slidesPerView]="slidesPerView" loop="true" speed="500"  space-between="20">
                    
                    <swiper-slide *ngFor="let item of mainnewslist.Content; let i = index" >
                        <div class="university-box"  >
                            <div class="university-img">
                                <img src="{{item.NewsThumbnailUrl}}"/>
                            </div>
                            <div class="news-content">
                                <h3><a href="news-details/{{item.ContentID}}">{{item.ContentTitle}}</a></h3>
                                <div class="calendar-icon">
                                    <ul style="display:flex;">
                                        <li style="list-style:none; padding-right:10px;"><img src="https://aiservice.aisectinfotech.com/NewsImages/calendar-icon.svg" title="Calendar" alt="Calendar"> {{item.CreatedDate | date: 'dd/MM/yyyy'}}</li>
                                        <li style="list-style:none; padding-right:10px;"><img src="https://aiservice.aisectinfotech.com/NewsImages/clock-icon.svg" title="Clock" alt="Clock"> {{item.CreatedDate | date: 'hh:mm a'}}</li>
                                    </ul>
                                </div> 
                            </div>
                        </div> 
                </swiper-slide>
            </swiper-container>
            </div>
        </div>
    </div>
</div>
</div>
