
<div class="latest-new-single">
    <div class="single-event-main align-items-center">
        <img src="{{latestNewsObj[0].NewsThumbnailUrl}}" class="imgcls" alt="" title="">
        <div class="single-event">
            <h3><a routerLink="/news-details/{{latestNewsObj[0].ContentID}}">{{latestNewsObj[0].ContentTitle}}</a></h3> 
            <!-- <p> {{latestNewsObj[0]?.ContentDiscription|striphtml}}</p> -->
            <div class="calendar-icon">
                <ul>
                    <li><img src="assets/images/calendar-icon.svg" title="Calendar" alt="Calendar"> {{latestNewsObj[0].CreatedDate | date: 'dd/MM/yyyy'}}</li>
                                <li><img src="assets/images/clock-icon.svg" title="Clock" alt="Clock">  {{latestNewsObj[0].CreatedDate | date: 'hh:mm a'}}</li>
                                <!-- <li><img src="assets/images/eye-icon.svg" title="Eye" alt="Eye"> 15</li> -->
                </ul>
            </div>
        </div>
    </div>
    <div class="event-new-main">
        <div class="row">
            <div class="col-md-6"  *ngFor="let item of latestNewsObj| slice:1:3;let i=index" >
                <div class="event-new">
                    <img src="{{item.NewsThumbnailUrl}}" alt="" title="">
                    <div class="event-content">
                    <h3><a routerLink="/news-details/{{item.ContentID}}">{{item.ContentTitle| slice:0:30 }}</a></h3>
                        <div class="calendar-icon">
                            <ul> 
                                <li><img src="assets/images/calendar-icon.svg" title="Calendar" alt="Calendar"> {{item.CreatedDate | date: 'dd/MM/yyyy'}}</li>
                                <li><img src="assets/images/clock-icon.svg" title="Clock" alt="Clock">  {{item.CreatedDate | date: 'hh:mm a'}}</li>
                                <!-- <li><img src="assets/images/eye-icon.svg" title="Eye" alt="Eye"> 15</li> -->
                            </ul>
                        </div>
                    </div>
                </div>
            </div> 
            
        </div>
    </div>
</div>