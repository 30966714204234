<section class="footer-section">
    <div class="container">
        <div class="row">
            <div class="col-md-3">
                <div class="footer-box1">
                    <img src="./assets/images/footer-logo.png" alt="Footer Logo" title="Footer Logo">
                    <!-- <p style="color:white !important;">छात्रों को अंतरराष्ट्रीय स्तर की शिक्षा एवं रोजगार के अवसर प्रदान करने के लिए मध्यप्रदेश के पहले कौशल आधारित विश्वविद्यालय रबीन्द्रनाथ टैगोर विश्वविद्यालय द्वारा जर्मन वार्सिटी के साथ सहयोग संबंधी एमओयू हस्ताक्षर किया गया है।</p> -->
                </div>
            </div>
            <div class="col-md-6">
                <div class="headquaters-box footer-category">
                    <h5>News Category</h5> 
                    <div class="row">
                        <div class="col-md-6">
                            <ul>
                                <li  *ngFor="let item of newsCategoryObj| slice:0:5;let i=index"><a href="news-list/{{item.CategoryID}}">{{item.CategoryName}}</a></li> 
                            </ul>
                        </div>
                        <div class="col-md-6">
                            <ul>
                                <li  *ngFor="let item of newsCategoryObj| slice:5:10;let i=index"><a href="news-list/{{item.CategoryID}}">{{item.CategoryName}}</a></li> 
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="headquaters-box">
                    <h5>Headquaters</h5>
                    <address>Scope Campus,NH-12, Near Misrod, Hoshangabad Road, Bhopal - 462026 (M.P.) India</address>
                    <a href="mailto:awaaz@aisect.org">awaaz@aisect.org</a>
                    <!-- <a href="tel:(+123) 456 789 101">(+123) 456 789 101</a> -->
                </div>
                <div class="follow-us-box">
                    <strong>Follow Us:</strong>
                    <ul>
                        <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                        <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                        <li><a href="#"><i class="fab fa-instagram"></i></a></li>
                        <li><a href="#"><i class="fab fa-linkedin-in"></i></a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="footer-two">
        <div class="container">
            <div class="row">
                <div class="col-md-6">Copyright© 2024 AISECT Bhopal, All Rights Reserved.</div>
                <!-- <div class="col-md-6">
                    <ul>
                        <li><a href="#">Terms of Use</a></li>
                        <li><a href="#">Ad Choices</a></li>
                        <li><a href="#">Licence Footage</a></li>
                        <li><a href="#">Sitemap</a></li>
                    </ul>
                </div> -->
            </div>
        </div>
    </div>
</section>
