import { Component, Input, OnInit } from '@angular/core';
import { INewscategory } from 'src/app/model/News';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css']
})
export class NotificationComponent implements OnInit {
  @Input() NewsCategroyList:Array<INewscategory>=[];
  constructor() { }

  ngOnInit(): void {
  }

}
