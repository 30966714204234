import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ILatestnews, IMenu, INewscategory, INewslist, NEWSDETAIL_API_RESPONSE } from 'src/app/model/News';
import { WebserviceService } from 'src/app/services/webservice.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  [x: string]: any;
  isSidebarOpen=false;
  search_keyward:any;
  mobileClass='nav-main mainmenu-nav';
  desktopClass='nav-main mainmenu-nav d-none d-xl-block';  
  apiSubs:any;
  menuListObj : Array<IMenu> = [];
  latestNewsObj:Array<ILatestnews>=[];
  newsCategoryObj:Array<INewscategory>=[];
  newsListObj:Array<INewslist>=[];
    
  step='step1';  
  @Input() className :any;
  constructor(private webService:WebserviceService,private router:Router) { }


  ngOnInit(): void {
    this.menuList();
   }
  openSidebar(){
    this.isSidebarOpen=true;
  }

  closeSidebar(){
    this.isSidebarOpen=false;
  }
 
  toggleoff(){

// alert('About us');

// setTimeout(() => {
//   console.log('hello');
// }, 1000);

this.openSidebar();
  }

  
  menuList(){
    this.apiSubs = this.webService.requestGet('menu').subscribe({
      next:(response:NEWSDETAIL_API_RESPONSE | any)=>{ 
            this.menuListObj = response.dataobject.menu; 
            this.latestNewsObj = response.dataobject.latestnews; 
            this.newsCategoryObj=response.dataobject.newscategory;
            this.newsListObj=response.dataobject.newslist; 
        },
      error:(e:any)=>{  
        this.menuListObj = [];
      }
    });
  }
  Search(form:any){
    console.log('Search Content',this.search_keyward)
    this.router.navigate(['/Search',this.search_keyward])
    .then(() => {
      window.location.reload();
    });
  }
} 
