import { Component, Input, OnInit } from '@angular/core';
import { ILatestnews } from 'src/app/model/News';

@Component({
  selector: 'app-latestnews-section',
  templateUrl: './latestnewslist.component.html',
  styleUrls: ['./latestnewslist.component.css']
})
export class LatestnewslistComponent implements OnInit {
 @Input()latestNewsObj:Array<ILatestnews>=[];
  constructor() { }

  ngOnInit(): void {
  }

}
