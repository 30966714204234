import { ActivatedRoute } from '@angular/router';
import { Component, Input, OnInit } from '@angular/core';
import { IContent, NEWSDETAIL_API_RESPONSE } from 'src/app/model/News';
import { WebserviceService } from 'src/app/services/webservice.service';
@Component({
  selector: 'app-newsdetail',
  templateUrl: './newsdetail.component.html',
  styleUrls: ['./newsdetail.component.css']
})
export class NewsdetailComponent implements OnInit {

  apiSubs:any;
  newsDetailObj:IContent=<IContent>{};
  @Input() className :any;
  ContentID:any;
  constructor(private webService:WebserviceService,private activeRoute:ActivatedRoute) { }

  ngOnInit(): void {
   this.ContentID = this.activeRoute.snapshot.params['id']; 
   if(this.ContentID)this.getNewsDetail(this.ContentID)
   console.log('this detail',this.ContentID)
  }

  getNewsDetail(ContentID:any){
    console.log('ContentID',ContentID)
    this.apiSubs = this.webService.requestGet('newsdetail?ContentID='+ContentID).subscribe({
      next:(response:NEWSDETAIL_API_RESPONSE | any)=>{ 
        this.newsDetailObj=response.dataobject; 
        },
      error:(e:any)=>{  
        this.newsDetailObj =<IContent>{};
      }
    });
  }
}